.rates-component {
  height: 100vh;
  background-color: rgb(249, 249, 249);

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head-txt {
      padding: 30px 50px;
      padding-right: 0px;
      font-size: 1.9rem;
      font-weight: 500;
      display: inline-block;
    }
  }
}
