.landing {
  height: 100vh;
  font-family: "Work Sans", sans-serif;
  background-color: #a36e29;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .sheet {
    width: calc(100vw / 3);
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 50px;

    .title {
      margin-bottom: 20px;
      font-size: 1.5rem;
      font-weight: 600;
    }

    .button {
      width: 200px;
      height: 50px;
      background-color: #a36e29;
      color: white;
      text-transform: none;
      font-family: "Work Sans", sans-serif;
      font-size: 1.1rem;

      &:hover {
        background-color: white;
        color: #a36e29;
        border: 1px solid #a36e29;
      }
    }
  }
}
