.drawer-item-selected {
  color: #a36e29;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  background-color: #f9f5ec;
  padding-left: 20px;

  .icon {
    padding-left: 20px;
    padding-right: 20px;
  }

  .title {
    font-size: 1.1rem;
  }

  .arrow{
    margin-left: auto;
  }
  &.sub-category {
    padding-left: 25px; 
    padding-top: 10px;
  padding-bottom: 10px;
  }
}

.drawer-item {
  
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: left;
  align-items: center;
  //border-radius: 20px;
  cursor: pointer;
  padding-left: 20px;

  .icon {
    padding-left: 20px;
    padding-right: 20px;
  }

  .title {
    font-size: 1.1rem;
  }
  .arrow{
    margin-left: auto;
    margin-right: 20px;

  }
  &.sub-category {
    padding-left: 25px;
    padding-top: 10px;
  padding-bottom: 10px;
  }
}
