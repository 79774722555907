.category {
    font-family: "Work Sans", sans-serif;
    display: flex;
  
    .divider {
      width: 1px;
      height: 100vh;
    }
  
    .component {
      width: 100%;
      height: 100vh;
    }
  }